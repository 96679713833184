document.addEventListener('DOMContentLoaded', function () {
    const links = document.querySelectorAll('.menue__link');

    links.forEach(link => {
        const isActive = link.classList.contains('menue__link--aktiv');

        if (!isActive) {
            link.setAttribute('data-grain', 'aktiv');
            
            // Event Listener für Hover-Events
            link.addEventListener('mouseover', function() {
                link.removeAttribute('data-grain');
            });

            link.addEventListener('mouseout', function() {
                link.setAttribute('data-grain', 'aktiv');
            });
        }
    });

    const farbwechslerMenue = new Farbwechsler({
        trigger: "#entstehung",
        buttons: ".menue__link",
        slider: ".projekt__konzept-wrap"
    });

    farbwechslerMenue.farbeWechselnTextMitSlider();
});
